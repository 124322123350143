/**
 *  项目的配置文件
 */
export const apiUrl = 'https://www.ti-dao.com';
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '梯到';//浏览器顶部title
export const imgUrl = "https://image.ti-dao.com";
export const chatUrl = "wss://im.ti-dao.com";
export const mUrl = 'https://m.ti-dao.com'
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

// ** copyright *** slodon *** version-v3.8 *** date-2022-03-01 ***主版本v3.8



